import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import { memo } from 'react'

import * as S from './styles'

const ContactQuestions = () => (
  <S.Section>
    <Wrapper>
      <S.Title>
        <Heading size="medium" type="h2" variant="primary-1">
          Vous avez une question&nbsp;?
        </Heading>
      </S.Title>

      <S.Content>
        <S.CardContainer>
          <Card variant="white" zDepth={2} format="full-height">
            <S.Card>
              <Content>
                <S.CardTitle>
                  <SubHeading type="h3">Comment ça marche&nbsp;?</SubHeading>
                </S.CardTitle>
                <S.CardContent>Découvrez le fonctionnement de Pretto.</S.CardContent>
                <S.Button>
                  <ButtonLegacy href="/notre-service/cest-quoi-pretto/" format="full">
                    Découvrir
                  </ButtonLegacy>
                </S.Button>
              </Content>
            </S.Card>
          </Card>
        </S.CardContainer>
        <S.CardContainer>
          <Card variant="white" zDepth={2} format="full-height">
            <S.Card>
              <Content>
                <S.CardTitle>
                  <SubHeading type="h3">Notre FAQ</SubHeading>
                </S.CardTitle>
                <S.CardContent>
                  Toutes les réponses aux questions que vous vous posez sur Pretto et votre prêt.
                </S.CardContent>
                <S.Button>
                  <ButtonLegacy href="https://faq.staging.pretto.fr/" variant="accent-1" format="full">
                    Consulter
                  </ButtonLegacy>
                </S.Button>
              </Content>
            </S.Card>
          </Card>
        </S.CardContainer>
      </S.Content>
    </Wrapper>
  </S.Section>
)

export default memo(ContactQuestions)
